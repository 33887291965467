import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState, memo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import * as Yup from "yup";
import { AddFeature } from "../../actions/feature";

const MAX_FILE_SIZE = 153600; //150KB

const validFileExtensions = { image: ["jpg", "png", "jpeg", "svg"] };

const AddFeatureModal = ({
  addFeatureModal,
  setAddFeatureModal,
  featuresRefetch,
}) => {
  const [preview, setPreview] = useState("https://via.placeholder.com/100");

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  const initialValues = {
    title: "",
    description: "",
    imageFile: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    imageFile: Yup.mixed()
      .required("Required")
      .test("is-valid-type", "Not a valid image type", (value) =>
        isValidFileType(value[0] && value[0].name.toLowerCase(), "image")
      )
      .test(
        "is-valid-size",
        "File must be less than 150KB",
        (value) => value[0] && value[0].size <= MAX_FILE_SIZE
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { mutate, isLoading } = useMutation((data) => AddFeature(data), {
    onSuccess: (res) => {
      reset();
      setPreview("https://via.placeholder.com/100");
      setAddFeatureModal(false);
      featuresRefetch();
      toast.success(res.data.message);
    },
    onError: (err) => {
      toast.error(err.response.data.error);
    },
  });

  const handleData = (values) => {
    let featuresData = new FormData();
    featuresData.append("title", values.title);
    featuresData.append("description", values.description);
    featuresData.append("icon", values.imageFile[0]);
    mutate(featuresData);
  };

  useEffect(() => {
    let values = getValues();
    if (values.imageFile.length) {
      setPreview(URL.createObjectURL(values.imageFile[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("imageFile")]);

  return (
    <Modal
      backdrop="static"
      open={addFeatureModal}
      onClose={() => setAddFeatureModal(false)}
      className=""
    >
      <Modal.Header>
        <Modal.Title className="text-primary text-lg font-semibold text-center">
          Add New Feature
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="mx-auto flex justify-center items-center p-2 h-[80px] w-[80px] md:h-[150px] md:w-[150px]">
            <img src={preview} alt="preview_image" />
          </div>
          <form onSubmit={handleSubmit(handleData)}>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="icon">
                Upload file
              </label>
              <input
                className="custom-input"
                aria-describedby="file_input_help"
                id="icon"
                name="icon"
                type="file"
                accept="image/*"
                {...register("imageFile")}
              />
              {errors.imageFile && (
                <p className="text-red-600">{errors.imageFile.message}</p>
              )}
            </div>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="title">
                Title
              </label>
              <input
                className="custom-input"
                id="title"
                name="title"
                type="text"
                {...register("title")}
              />
              {errors.title && (
                <p className="text-red-600">{errors.title.message}</p>
              )}
            </div>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="description">
                Description
              </label>
              <textarea
                className="custom-input"
                id="description"
                name="description"
                rows={4}
                {...register("description")}
              />
              {errors.description && (
                <p className="text-red-600">{errors.description.message}</p>
              )}
            </div>
            <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
              <button
                className="submit-btn"
                type="submit"
                disabled={!isValid || !isDirty || isLoading}
              >
                Submit
              </button>
              <button className="submit-btn">Cancel</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(AddFeatureModal);
