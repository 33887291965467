import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { GetAllQueries, ToggleQuery } from "../actions/customer-queries";
import { FcRemoveImage } from "react-icons/fc";
import DeleteQueryModal from "../components/customer-queries/DeleteQueryModal";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";

const CustomerQueries = () => {
  const [deleteQueryModal, setDeleteQueryModal] = useState(false);

  const [currentQuery, setCurrentQuery] = useState(null);

  const {
    data: queriesData,
    isLoading: queriesLoading,
    isError: queriesError,
    refetch: queriesRefetch,
  } = useQuery(["queries-data"], GetAllQueries, {
    select: (res) => res.data,
  });

  const { mutate: toggleQuery, isLoading: toggleLoading } = useMutation(
    (data) => ToggleQuery(data),
    {
      onSuccess: (res) => {
        queriesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "capitalize",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "capitalize",
        fontSize: "17px",
        color: "#ffffff",
        // margin: "auto",
        // textAlign: "center",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      // maxWidth: "fit-content",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      // maxWidth: "250px",
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
      // maxWidth: "fit-content",
    },
    {
      name: "Topic",
      selector: (row) => row.topic,
      // maxWidth: "fit-content",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      // maxWidth: "fit-content",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // maxWidth: "fit-content",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      // maxWidth: "fit-content",
    },
  ];

  const tableData =
    !queriesLoading && !queriesError
      ? queriesData.map((item, i) => {
          return {
            sno: i + 1,
            name: item.name,
            email: item.email,
            contact: item.phone,
            topic: item.topic,
            message: item.message,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  loading={toggleLoading}
                  checked={item.isActive}
                  checkedChildren="Enable"
                  unCheckedChildren="Disabled"
                  onChange={() => toggleQuery(item._id)}
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-4xl">
                <FcRemoveImage
                  onClick={() => {
                    setCurrentQuery(item);
                    setDeleteQueryModal(true);
                  }}
                  className={`cursor-pointer`}
                />
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { icon, actions, status, ...rest } = data;
    return (
      <pre className="break-words whitespace-pre-wrap">
        {JSON.stringify(rest, null, 2)}
      </pre>
    );
  };

  return (
    <div className="">
      <DeleteQueryModal
        deleteQueryModal={deleteQueryModal}
        setDeleteQueryModal={setDeleteQueryModal}
        queriesRefetch={queriesRefetch}
        currentQuery={currentQuery}
      />

      <h1 className="text-center text-4xl text-primary font-semibold tracking-wider py-5">
        Customer Queries
      </h1>

      <div className="p-2 md:p-10">
        <DataTable
          title="Queries List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default CustomerQueries;
