import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "rsuite";
import { DeleteSocialMedia } from "../../actions/social-media";

const DeleteSocialMediaModal = ({
  deleteSocialMediaModal,
  setDeleteSocialMediaModal,
  socialMediaRefetch,
  currentSocialMedia,
}) => {
  const { mutate: deleteSocialMedia, isLoading: deleteLoading } = useMutation(
    (data) => DeleteSocialMedia(data),
    {
      onSuccess: (res) => {
        setDeleteSocialMediaModal(false);
        socialMediaRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={deleteSocialMediaModal}
      onClose={() => setDeleteSocialMediaModal(false)}
      size="xs"
    >
      <Modal.Body>Do you want to delete this carousel ?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => deleteSocialMedia(currentSocialMedia._id)}
          appearance="primary"
          disabled={deleteLoading}
        >
          Ok
        </Button>
        <Button
          onClick={() => setDeleteSocialMediaModal(false)}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSocialMediaModal;
