import api from "../lib/api";

export const AddService = (data) => {
  return api.post("/add-new-service", data);
};

export const UpdateImage = (data, id) => {
  return api.post(`/update-service-image/${id}`, data);
};

export const UpdateServiceData = (data, id) => {
  return api.post(`/update-service-data/${id}`, data);
};

export const DeleteService = (data) => {
  const { _id, icon } = data;
  return api.delete(`/delete-service/${_id}/${icon}`);
};

export const ToggleService = (id) => {
  return api.post(`/toggle-service/${id}`);
};

export const GetAllServices = () => {
  return api.get("/get-all-services");
};
