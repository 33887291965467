import { useState } from "react";
import { Navbar, Nav } from "rsuite";
import { FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar";
import { useMutation } from "@tanstack/react-query";
import { Signout } from "../../actions/auth";
import { useAuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthContext();
  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useMutation(() => Signout(), {
    onSuccess: (res) => {
      signOut();
      localStorage.removeItem("authToken");
      navigate("/login");
      toast.success(res.data.message);
    },
    onError: (err) => {
      toast.error(err.response.data.error.message);
    },
  });

  return (
    <div className="bg-darkPurple flex justify-between items-center">
      <Sidebar open={open} setOpen={setOpen} />
      <FaBars
        className="text-3xl text-white ml-2 md:ml-10 cursor-pointer"
        onClick={() => setOpen(true)}
      />
      <Navbar className="bg-darkPurple">
        <Nav pullRight className="text-gray-300">
          <Nav.Item>Home</Nav.Item>
          <Nav.Menu title="About">
            <Nav.Item>Company</Nav.Item>
            <Nav.Item>Team</Nav.Item>
            <Nav.Item onClick={mutate} disabled={isLoading}>
              Logout
            </Nav.Item>
          </Nav.Menu>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavBar;
