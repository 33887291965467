import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "rsuite";
import { DeleteStrategy } from "../../actions/strategy";

const DeleteStrategyModal = ({
  deleteStrategyModal,
  setDeleteStrategyModal,
  strategiesRefetch,
  currentStrategy,
}) => {
  const { mutate: deleteStrategy, isLoading: deleteLoading } = useMutation(
    (data) => DeleteStrategy(data),
    {
      onSuccess: (res) => {
        setDeleteStrategyModal(false);
        strategiesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={deleteStrategyModal}
      onClose={() => setDeleteStrategyModal(false)}
      size="xs"
    >
      <Modal.Body>Do you want to delete this strategy ?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => deleteStrategy(currentStrategy)}
          appearance="primary"
          disabled={deleteLoading}
        >
          Ok
        </Button>
        <Button
          onClick={() => setDeleteStrategyModal(false)}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteStrategyModal;
