import api from "../lib/api";

export const AddOrder = (data) => {
  return api.post("/order/add-new-order", data);
};

export const UpdateOrder = (data, id) => {
  return api.post(`/order/update-order-data/${id}`, data);
};

export const UpdateOrderStatus = (id) => {
  return api.post(`/order/update-order-status/${id}`);
};

export const GetAllOrdersByCompany = (id) => {
  return api.get(`/order/get-all-orders-by-company/${id}`);
};

export const GetAllOrders = () => {
  return api.get("/order/get-user-orders");
};
