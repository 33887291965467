import React from "react";
import { MdOutlineArrowUpward, MdTrendingUp } from "react-icons/md";

const InfoCard = (props) => {
  const { title, separator, data1, data2, icon } = props;
  return (
    <div className="flex-1 flex flex-col justify-start gap-y-5 bg-white rounded-sm border border-gray-200 p-2 md:p-8">
      {icon && (
        <props.icon className="text-primary text-2xl md:text-4xl cursor-pointer bg-gray-200 rounded-full p-2 hidden md:flex" />
      )}
      <div className="flex justify-between items-end">
        <div>
          <h2 className="font-semibold text-gray-800 text-xl md:text-2xl">
            {data1}&nbsp;{separator}
          </h2>
          <span className="text-gray-400 text-base 2xl:text-lg capitalize">
            {title}
          </span>
        </div>
        <div className="flex justify-center items-center gap-x-1 text-green-500 text-base md:text-lg">
          <span className="font-medium">{data2}%</span>
          <MdOutlineArrowUpward />
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
