import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React, { memo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import * as Yup from "yup";
import { AddSocialMedia } from "../../actions/social-media";

const AddSocialMediaModal = ({
  addSocialMediaModal,
  setAddSocialMediaModal,
  socialMediaRefetch,
}) => {
  const initialValues = {
    title: "",
    address: "",
    icon: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    icon: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { mutate, isLoading } = useMutation((data) => AddSocialMedia(data), {
    onSuccess: (res) => {
      reset();
      setAddSocialMediaModal(false);
      socialMediaRefetch();
      toast.success(res.data.message);
    },
    onError: (err) => {
      toast.error(err.response.data.error);
    },
  });

  const handleData = (values) => {
    mutate(values);
  };

  return (
    <Modal
      backdrop="static"
      open={addSocialMediaModal}
      onClose={() => setAddSocialMediaModal(false)}
      className=""
    >
      <Modal.Header>
        <Modal.Title className="text-primary text-lg font-semibold text-center">
          Add New Social Media
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={handleSubmit(handleData)}>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="title">
                Title
              </label>
              <input
                className="custom-input"
                id="title"
                name="title"
                type="text"
                {...register("title")}
              />
              {errors.title && (
                <p className="text-red-600">{errors.title.message}</p>
              )}
            </div>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="address">
                Address
              </label>
              <input
                className="custom-input"
                id="address"
                name="address"
                type="text"
                {...register("address")}
              />
              {errors.address && (
                <p className="text-red-600">{errors.address.message}</p>
              )}
            </div>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="icon">
                Icon
              </label>
              <input
                className="custom-input"
                id="icon"
                name="icon"
                type="text"
                {...register("icon")}
              />
              {errors.icon && (
                <p className="text-red-600">{errors.icon.message}</p>
              )}
            </div>
            <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
              <button
                className="submit-btn"
                type="submit"
                disabled={!isValid || !isDirty || isLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(AddSocialMediaModal);
