import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React, { memo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import * as Yup from "yup";
import { AddCompany } from "../../actions/company";

const AddCompanyModal = ({ isOpen, setIsOpen, refetchList }) => {
  const initialValues = {
    title: "",
    description: "",
    symbol: "",
    category: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    symbol: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { mutate, isLoading } = useMutation((data) => AddCompany(data), {
    onSuccess: (res) => {
      reset();
      setIsOpen(false);
      refetchList();
      toast.success(res.data.message);
    },
    onError: (err) => {
      toast.error(err.response.data.error);
    },
  });

  const handleData = (values) => {
    // console.log(values);
    mutate({
      ...values,
      title: values.title.toLowerCase(),
      symbol: values.symbol.toLowerCase(),
      category: values.category.toLowerCase(),
      description: values.description.toLowerCase(),
    });
  };

  return (
    <Modal
      backdrop="static"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className=""
    >
      <Modal.Header>
        <Modal.Title className="text-primary text-lg font-semibold text-center">
          Add New Company
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={handleSubmit(handleData)}>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="title">
                Title
              </label>
              <input
                className="custom-input"
                id="title"
                name="title"
                type="text"
                {...register("title")}
              />
              {errors.title && (
                <p className="text-red-600">{errors.title.message}</p>
              )}
            </div>

            <div className="w-full mb-6 flex gap-5">
              <div className="flex-1">
                <label className="custom-label" htmlFor="symbol">
                  Symbol
                </label>
                <input
                  className="custom-input"
                  id="symbol"
                  name="symbol"
                  type="text"
                  {...register("symbol")}
                />
                {errors.symbol && (
                  <p className="text-red-600">{errors.symbol.message}</p>
                )}
              </div>

              <div className="flex-1">
                <label className="custom-label" htmlFor="category">
                  Category
                </label>
                <input
                  className="custom-input"
                  id="category"
                  name="category"
                  type="text"
                  {...register("category")}
                />
                {errors.category && (
                  <p className="text-red-600">{errors.category.message}</p>
                )}
              </div>
            </div>

            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="description">
                Description
              </label>
              <textarea
                className="custom-input"
                id="description"
                name="description"
                rows={3}
                {...register("description")}
              />
              {errors.description && (
                <p className="text-red-600">{errors.description.message}</p>
              )}
            </div>

            <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
              <button
                className="submit-btn"
                type="submit"
                disabled={!isValid || !isDirty || isLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(AddCompanyModal);
