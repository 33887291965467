import api from "../lib/api";

export const AddStrategy = (data) => {
  return api.post("/add-new-strategy", data);
};

export const UpdateImage = (data, id) => {
  return api.post(`/update-strategy-image/${id}`, data);
};

export const UpdateStrategyData = (data, id) => {
  return api.post(`/update-strategy-data/${id}`, data);
};

export const DeleteStrategy = (data) => {
  const { _id, icon } = data;
  return api.delete(`/delete-strategy/${_id}/${icon}`);
};

export const ToggleStrategy = (id) => {
  return api.post(`/toggle-strategy/${id}`);
};

export const GetAllStrategy = () => {
  return api.get("/get-all-strategy");
};
