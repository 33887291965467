import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "rsuite";
import { DeleteVacancy } from "../../actions/vacancies";

const DeleteVacancyModal = ({
  deleteVacancyModal,
  setDeleteVacancyModal,
  vacanciesRefetch,
  currentVacancy,
}) => {
  const { mutate: deleteVacancy, isLoading: deleteLoading } = useMutation(
    (data) => DeleteVacancy(data),
    {
      onSuccess: (res) => {
        setDeleteVacancyModal(false);
        vacanciesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={deleteVacancyModal}
      onClose={() => setDeleteVacancyModal(false)}
      size="xs"
    >
      <Modal.Body>Do you want to delete this vacancy ?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => deleteVacancy(currentVacancy)}
          appearance="primary"
          disabled={deleteLoading}
        >
          Ok
        </Button>
        <Button
          onClick={() => setDeleteVacancyModal(false)}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteVacancyModal;
