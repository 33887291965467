import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "rsuite";
import { DeleteService } from "../../actions/services";

const DeleteServiceModal = ({
  deleteServiceModal,
  setDeleteServiceModal,
  servicesRefetch,
  currentService,
}) => {
  const { mutate: deleteService, isLoading: deleteLoading } = useMutation(
    (data) => DeleteService(data),
    {
      onSuccess: (res) => {
        setDeleteServiceModal(false);
        servicesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={deleteServiceModal}
      onClose={() => setDeleteServiceModal(false)}
      size="xs"
    >
      <Modal.Body>Do you want to delete this service ?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => deleteService(currentService)}
          appearance="primary"
          disabled={deleteLoading}
        >
          Ok
        </Button>
        <Button
          onClick={() => setDeleteServiceModal(false)}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteServiceModal;
