import api from "../lib/api";

export const Signin = (data) => {
  return api.post("/admin-login", data);
};

export const GetAdmin = () => {
  return api.get("/get-admin");
};

export const Signout = () => {
  return api.post("/admin-logout");
};
