import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetAllProfiles, UpdateProfileData } from "../actions/profile";

const Profile = () => {
  const {
    data: profileData,
    isLoading: profileLoading,
    refetch: profileRefetch,
  } = useQuery(["profile-data"], GetAllProfiles, {
    select: (res) => res.data,
  });

  const initialValues = {
    phone: "",
    email: "",
    address: "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { mutate, isLoading } = useMutation(
    (data) => UpdateProfileData(data, profileData && profileData[0]._id),
    {
      onSuccess: (res) => {
        profileRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const handleProfile = (values) => {
    mutate({
      phone: values.phone.split(","),
      email: values.email.split(","),
      address: values.address.split(":"),
    });
  };

  useEffect(() => {
    if (profileData?.length > 0) {
      setValue("phone", profileData ? profileData[0]?.phone.toString() : "");
      setValue("email", profileData ? profileData[0]?.email.toString() : "");
      setValue("address", profileData ? profileData[0]?.address.join(":") : "");
    }
  }, [profileData, setValue]);

  return (
    <div>
      <h1 className="text-center text-4xl text-primary font-semibold tracking-wider py-5">
        Profile
      </h1>
      <div className="my-5 max-w-md mx-auto p-2">
        <form onSubmit={handleSubmit(handleProfile)}>
          <div className="w-full mb-6">
            <label className="custom-label" htmlFor="phone">
              Contact
            </label>
            <textarea
              className="custom-input"
              id="phone"
              name="phone"
              type="text"
              {...register("phone")}
            />
            <p className="text-red-600">Use (,) to enter multiple numbers.</p>
            {errors.phone && (
              <p className="text-red-600">{errors.phone.message}</p>
            )}
          </div>
          <div className="w-full mb-6">
            <label className="custom-label" htmlFor="email">
              Email
            </label>
            <textarea
              className="custom-input"
              id="email"
              name="email"
              type="text"
              {...register("email")}
            />
            <p className="text-red-600">Use (,) to enter multiple emails.</p>
            {errors.email && (
              <p className="text-red-600">{errors.email.message}</p>
            )}
          </div>
          <div className="w-full mb-6">
            <label className="custom-label" htmlFor="address">
              Address
            </label>
            <textarea
              className="custom-input"
              id="address"
              name="address"
              rows={3}
              {...register("address")}
            />
            <p className="text-red-600">Use (:) to enter multiple address.</p>
            {errors.address && (
              <p className="text-red-600">{errors.address.message}</p>
            )}
          </div>
          <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
            <button
              className="submit-btn"
              type="submit"
              disabled={!isValid || !isDirty || isLoading}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
