import api from "../lib/api";

export const AddProduct = (data) => {
  return api.post("/add-new-product", data);
};

export const UpdateImage = (data, id) => {
  return api.post(`/update-product-image/${id}`, data);
};

export const UpdateProductData = (data, id) => {
  return api.post(`/update-product-data/${id}`, data);
};

export const DeleteProduct = (data) => {
  const { _id, icon } = data;
  return api.delete(`/delete-product/${_id}/${icon}`);
};

export const ToggleProduct = (id) => {
  return api.post(`/toggle-product/${id}`);
};

export const GetAllProduct = () => {
  return api.get("/get-all-product");
};
