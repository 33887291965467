import api from "../lib/api";

export const DeleteApplication = (data) => {
  const { _id, resume } = data;
  return api.delete(`/delete-job-application/${_id}/${resume}`);
};

export const ToggleApplication = (id) => {
  return api.post(`/toggle-job-application/${id}`);
};

export const GetAllApplications = () => {
  return api.get("/get-all-job-application");
};
