import React from "react";
import * as Yup from "yup";
import { Signin } from "../../actions/auth";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

function LoginForm() {
  const navigate = useNavigate();
  const { signIn } = useAuthContext();

  const { mutate, isLoading } = useMutation((data) => Signin(data), {
    onSuccess: (res) => {
      signIn(res.data);
      localStorage.setItem("authToken", res.data.token);
      navigate("/");
      toast.success("Welcome Admin");
    },
    onError: (err) => {
      toast.error(err.response.data.error);
    },
  });

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const handleLogin = (values) => {
    mutate(values);
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <div className="mb-6">
        <div className="w-full">
          <label className="custom-label" htmlFor="username">
            Username
          </label>
          <input
            className={`custom-input ${
              errors.username ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Username"
            type="text"
            name="username"
            id="username"
            {...register("username")}
          />
          {errors.username && (
            <p className="text-red-600">{errors.username.message}</p>
          )}
        </div>
      </div>

      <div className="mb-6">
        <div className="w-full">
          <label className="custom-label" htmlFor="password">
            Password
          </label>
          <input
            className={`custom-input ${
              errors.password ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Password"
            type="password"
            name="password"
            id="password"
            {...register("password")}
          />
          {errors.password && (
            <p className="text-red-600">{errors.password.message}</p>
          )}
        </div>
      </div>

      <button
        className="submit-btn"
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        type="submit"
        disabled={!isValid || !isDirty || isLoading}
      >
        Submit
      </button>
    </form>
  );
}

export default LoginForm;
