import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";
import {
  GetAllCategories,
  GetAllCompanies,
  UpdateCompanyStatus,
} from "../../actions/company";
import AddCompanyModal from "../../components/company/AddCompanyModal";
import {
  MdKeyboardBackspace,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import UpdateCompanyModal from "../../components/company/UpdateCompanyModal";
import { Link, useNavigate } from "react-router-dom";

const Company = () => {
  const navigate = useNavigate();
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [updateCompanyModal, setUpdateCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const {
    data: companiesData,
    isLoading: companiesLoading,
    isError: companiesError,
    refetch: companiesRefetch,
  } = useQuery(["companies-data"], GetAllCompanies, {
    select: (res) => res.data,
  });

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    isError: categoriesError,
    refetch: categoriesRefetch,
  } = useQuery(["categories-data"], GetAllCategories, {
    select: (res) => res.data,
  });

  const { mutate: toggleSocialMedia, isLoading: toggleLoading } = useMutation(
    (data) => UpdateCompanyStatus(data),
    {
      onSuccess: (res) => {
        companiesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "capitalize",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "capitalize",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      maxWidth: "300px",
    },
    {
      name: "Symbol",
      selector: (row) => row.symbol,
      maxWidth: "200px",
      style: {
        textTransform: "uppercase",
      },
    },
    {
      name: "Category",
      selector: (row) => row.category,
      maxWidth: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "150px",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  const tableData =
    !companiesLoading && !companiesError
      ? companiesData.map((item, i) => {
          return {
            sno: i + 1,
            title: item.title,
            symbol: item.symbol,
            category: item.category,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  size={"md"}
                  loading={toggleLoading}
                  checked={item.isActive}
                  // checkedChildren="Enable"
                  // unCheckedChildren="Disabled"
                  onChange={() => toggleSocialMedia(item._id)}
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-2xl">
                <MdOutlineEdit
                  onClick={() => {
                    setSelectedCompany(item);
                    setUpdateCompanyModal(true);
                  }}
                  className="cursor-pointer"
                />
                <Link
                  to={`/company/company-analysis/${item._id}`}
                  state={{ companyTitle: item.title }}
                >
                  <MdOutlineRemoveRedEye className="cursor-pointer" />
                </Link>
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { actions, status, ...rest } = data;
    return <pre>{JSON.stringify(rest, null, 2)}</pre>;
  };

  return (
    <div className="">
      <AddCompanyModal
        isOpen={addCompanyModal}
        setIsOpen={setAddCompanyModal}
        refetchList={companiesRefetch}
      />

      <UpdateCompanyModal
        isOpen={updateCompanyModal}
        setIsOpen={setUpdateCompanyModal}
        refetchList={companiesRefetch}
        itemData={selectedCompany}
      />

      <div className="flex items-center w-11/12 mx-auto">
        <MdKeyboardBackspace
          className="text-primary text-4xl md:text-6xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="text-center text-xl md:text-4xl text-primary font-semibold tracking-wider py-5 capitalize flex-1">
          Company
        </h1>
      </div>
      <button className="add-new-btn" onClick={() => setAddCompanyModal(true)}>
        Add New Company
      </button>

      <div className="p-2 md:p-10 w-full">
        <DataTable
          title="Companies List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default Company;
