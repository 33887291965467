import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "rsuite";
import { DeleteCarousel } from "../../actions/carousel";

const DeleteCarouselModal = ({
  deleteCarouselModal,
  setDeleteCarouselModal,
  carouselRefetch,
  currentCarousel,
}) => {
  const { mutate: deleteCarousel, isLoading: deleteLoading } = useMutation(
    (data) => DeleteCarousel(data),
    {
      onSuccess: (res) => {
        setDeleteCarouselModal(false);
        carouselRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={deleteCarouselModal}
      onClose={() => setDeleteCarouselModal(false)}
      size="xs"
    >
      <Modal.Body>Do you want to delete this carousel ?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => deleteCarousel(currentCarousel)}
          appearance="primary"
          disabled={deleteLoading}
        >
          Ok
        </Button>
        <Button
          onClick={() => setDeleteCarouselModal(false)}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCarouselModal;
