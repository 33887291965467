import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import * as Yup from "yup";
import { UpdateSocialMediaData } from "../../actions/social-media";

const UpdateSocialMediaModal = ({
  updateSocialMediaModal,
  setUpdateSocialMediaModal,
  socialMediaRefetch,
  selectedSocialMedia,
}) => {
  const initialValues = {
    title: "",
    address: "",
    icon: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    icon: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (selectedSocialMedia) {
      setValue("title", selectedSocialMedia.title);
      setValue("address", selectedSocialMedia.address);
      setValue("icon", selectedSocialMedia.icon);
    }
  }, [selectedSocialMedia, setValue]);

  const { mutate: mutateDataUpdate, isLoading: updateLoading } = useMutation(
    (data) => UpdateSocialMediaData(data, selectedSocialMedia._id),
    {
      onSuccess: (res) => {
        setUpdateSocialMediaModal(false);
        socialMediaRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const handleData = (values) => {
    mutateDataUpdate(values);
  };

  return (
    <Modal
      backdrop="static"
      open={updateSocialMediaModal}
      onClose={() => setUpdateSocialMediaModal(false)}
      className=""
    >
      <Modal.Header>
        <Modal.Title className="text-primary text-lg font-semibold text-center">
          Update Social Media
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={handleSubmit(handleData)}>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="title">
                Title
              </label>
              <input
                className="custom-input"
                id="title"
                name="title"
                type="text"
                {...register("title")}
              />
              {errors.title && (
                <p className="text-red-600">{errors.title.message}</p>
              )}
            </div>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="address">
                Address
              </label>
              <input
                className="custom-input"
                id="address"
                name="address"
                {...register("address")}
              />
              {errors.address && (
                <p className="text-red-600">{errors.address.message}</p>
              )}
            </div>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="icon">
                Icon
              </label>
              <input
                className="custom-input"
                id="icon"
                name="icon"
                {...register("icon")}
              />
              {errors.icon && (
                <p className="text-red-600">{errors.icon.message}</p>
              )}
            </div>
            <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
              <button
                className="submit-btn"
                type="submit"
                disabled={!isValid || !isDirty || updateLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSocialMediaModal;
