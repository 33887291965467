import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import * as Yup from "yup";
import { AddOrder } from "../../actions/order";

const AddOrderModal = ({ isOpen, setIsOpen, refetchList, primaryData }) => {
  const [currentAmount, setCurrentAmount] = useState(0);

  const initialValues = {
    company: "",
    bidType: "",
    bidAmount: null,
    orderDate: "",
    quantity: null,
  };

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Required"),
    bidType: Yup.string().required("Required"),
    bidAmount: Yup.number()
      .positive("Number must be greater than 0")
      .required("Required"),
    orderDate: Yup.string().required("Required"),
    quantity: Yup.number()
      .positive("Number must be greater than 0")
      .integer("Quantity must be an integer")
      .required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { mutate, isLoading } = useMutation((data) => AddOrder(data), {
    onSuccess: (res) => {
      reset();
      setIsOpen(false);
      refetchList();
      toast.success(res.data.message);
    },
    onError: (err) => {
      toast.error(err.response.data.error);
    },
  });

  const handleData = (values) => {
    mutate({ ...values, amount: values.bidAmount * values.quantity });
  };

  const calculateAmount = () => {
    const { bidAmount, quantity } = getValues();
    if (bidAmount > 0 && quantity > 0) {
      setCurrentAmount(bidAmount * quantity);
    } else {
      setCurrentAmount(0); // Set a default value for invalid amounts
    }
  };

  useEffect(() => {
    const calculateAmountInterval = setInterval(calculateAmount, 500); // Perform calculation at most every 500ms
    calculateAmount(); // Calculate on initial render

    return () => clearInterval(calculateAmountInterval);
  }, [watch("bidAmount"), watch("quantity")]);

  return (
    <Modal
      backdrop="static"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className=""
    >
      <Modal.Header>
        <Modal.Title className="text-primary text-lg font-semibold text-center">
          Add New Order
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={handleSubmit(handleData)}>
            <div className="w-full mb-6 flex flex-col md:flex-row gap-5">
              <div className="flex-1">
                <label className="custom-label" htmlFor="company">
                  Company
                </label>
                <select
                  className="custom-input capitalize"
                  id="company"
                  name="company"
                  {...register("company")}
                >
                  <option value="">Select One</option>
                  {primaryData &&
                    primaryData.map((item, i) => (
                      <option key={i} value={item._id} className="capitalize">
                        {item.title}
                      </option>
                    ))}
                </select>
                {errors.company && (
                  <p className="text-red-600">{errors.company.message}</p>
                )}
              </div>

              <div className="flex-1">
                <label className="custom-label" htmlFor="bidType">
                  Bid Type
                </label>
                <select
                  className="custom-input capitalize"
                  id="bidType"
                  name="bidType"
                  {...register("bidType")}
                >
                  <option value="">Select One</option>
                  <option value="buy">Buy</option>
                  <option value="sell">Sell</option>
                </select>
                {errors.bidType && (
                  <p className="text-red-600">{errors.bidType.message}</p>
                )}
              </div>
              <div className="flex-1">
                <label className="custom-label" htmlFor="bidAmount">
                  Bid Amount
                </label>
                <input
                  className="custom-input"
                  id="bidAmount"
                  name="bidAmount"
                  type="number"
                  step="0.01"
                  {...register("bidAmount")}
                />
                {errors.bidAmount && (
                  <p className="text-red-600">{errors.bidAmount.message}</p>
                )}
              </div>
            </div>

            <div className="w-full mb-6 flex flex-col md:flex-row gap-5">
              <div className="flex-1">
                <label className="custom-label" htmlFor="amount">
                  Amount
                </label>
                <div className="custom-input">{currentAmount.toFixed(2)}</div>
              </div>

              <div className="flex-1">
                <label className="custom-label" htmlFor="quantity">
                  Quantity
                </label>
                <input
                  className="custom-input"
                  id="quantity"
                  name="quantity"
                  type="number"
                  {...register("quantity")}
                />
                {errors.quantity && (
                  <p className="text-red-600">{errors.quantity.message}</p>
                )}
              </div>

              <div className="flex-1">
                <label className="custom-label" htmlFor="orderDate">
                  Order Date
                </label>
                <input
                  className="custom-input"
                  id="orderDate"
                  name="orderDate"
                  type="date"
                  {...register("orderDate")}
                />
                {errors.orderDate && (
                  <p className="text-red-600">{errors.orderDate.message}</p>
                )}
              </div>
            </div>

            <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
              <button
                className="submit-btn"
                type="submit"
                disabled={!isValid || !isDirty || isLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(AddOrderModal);
