import Layout from "./components/layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/rsuite.min.css";
import "./index.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";
import Login from "./pages/auth/Login";
import Home from "./pages/Home";
import Services from "./pages/add/Services";
import Vacancies from "./pages/add/Vacancies";
import JobApplications from "./pages/JobApplications";
import CustomerQueries from "./pages/CustomerQueries";
import Carousel from "./pages/add/Carousel";
import Feature from "./pages/add/Feature";
import Product from "./pages/add/Product";
import Strategy from "./pages/add/Strategy";
import Profile from "./pages/Profile";
import SocialMedia from "./pages/add/SocialMedia";
import Company from "./pages/add/Company";
import Order from "./pages/add/Order";
import CompanyAnalysis from "./pages/company/CompanyAnalysis";
import Ledger from "./pages/stocks/Ledger";

function App() {
  const { user } = useAuthContext();

  function PrivateRoute({ children }) {
    return user !== null && user?._id ? children : <Navigate to="/login" />;
  }

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/services"
            element={
              <PrivateRoute>
                <Services />
              </PrivateRoute>
            }
          />
          <Route
            path="/vacancies"
            element={
              <PrivateRoute>
                <Vacancies />
              </PrivateRoute>
            }
          />
          <Route
            path="/carousel"
            element={
              <PrivateRoute>
                <Carousel />
              </PrivateRoute>
            }
          />
          <Route
            path="/features"
            element={
              <PrivateRoute>
                <Feature />
              </PrivateRoute>
            }
          />
          <Route
            path="/products"
            element={
              <PrivateRoute>
                <Product />
              </PrivateRoute>
            }
          />
          <Route
            path="/strategies"
            element={
              <PrivateRoute>
                <Strategy />
              </PrivateRoute>
            }
          />
          <Route
            path="/job-applications"
            element={
              <PrivateRoute>
                <JobApplications />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer-queries"
            element={
              <PrivateRoute>
                <CustomerQueries />
              </PrivateRoute>
            }
          />
          <Route
            path="/social-media"
            element={
              <PrivateRoute>
                <SocialMedia />
              </PrivateRoute>
            }
          />

          <Route
            path="/company"
            element={
              <PrivateRoute>
                <Company />
              </PrivateRoute>
            }
          />

          <Route
            path="/order"
            element={
              <PrivateRoute>
                <Order />
              </PrivateRoute>
            }
          />

          <Route
            path="/stocks/ledger"
            element={
              <PrivateRoute>
                <Ledger />
              </PrivateRoute>
            }
          />

          <Route
            path="/company/company-analysis/:companyId"
            element={
              <PrivateRoute>
                <CompanyAnalysis />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
