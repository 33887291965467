import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React, { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import * as Yup from "yup";
import moment from "moment";
import { UpdateEntry } from "../../actions/ledger";

const UpdateEntryModal = ({ isOpen, setIsOpen, refetchList, itemData }) => {
  const validationSchema = Yup.object({
    bidType: Yup.string().required("Required"),
    amount: Yup.number()
      .moreThan(0, "Number must be greater than 0.")
      .required("Required"),
    bidDate: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    setValue,
  } = useForm({
    // defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (itemData) {
      setValue("bidType", itemData.bidType);
      setValue("amount", itemData.amount);
      setValue("bidDate", moment(itemData.bidDate).format("YYYY-MM-DD"));
    }
  }, [itemData]);

  const { mutate, isLoading } = useMutation(
    (data) => UpdateEntry(data, itemData._id),
    {
      onSuccess: (res) => {
        reset();
        setIsOpen(false);
        refetchList();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const handleData = (values) => {
    // console.log(values);
    mutate(values);
  };

  return (
    <Modal
      backdrop="static"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className=""
    >
      <Modal.Header>
        <Modal.Title className="text-primary text-lg font-semibold text-center">
          Update Entry
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={handleSubmit(handleData)}>
            <div className="w-full mb-6 flex flex-col md:flex-row gap-5">
              <div className="flex-1">
                <label className="custom-label" htmlFor="amount">
                  Amount
                </label>
                <input
                  className="custom-input"
                  id="amount"
                  name="amount"
                  type="number"
                  step="0.01"
                  {...register("amount")}
                />
                {errors.amount && (
                  <p className="text-red-600">{errors.amount.message}</p>
                )}
              </div>

              <div className="flex-1">
                <label className="custom-label" htmlFor="bidType">
                  Bid Type
                </label>
                <select
                  className="custom-input capitalize"
                  id="bidType"
                  name="bidType"
                  {...register("bidType")}
                >
                  <option value="">Select One</option>
                  <option value="credit">Credit</option>
                  <option value="debit">Debit</option>
                </select>
                {errors.bidType && (
                  <p className="text-red-600">{errors.bidType.message}</p>
                )}
              </div>

              <div className="flex-1">
                <label className="custom-label" htmlFor="bidDate">
                  Bid Date
                </label>
                <input
                  className="custom-input"
                  id="bidDate"
                  name="bidDate"
                  type="date"
                  {...register("bidDate")}
                />
                {errors.bidDate && (
                  <p className="text-red-600">{errors.bidDate.message}</p>
                )}
              </div>
            </div>

            <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
              <button
                className="submit-btn"
                type="submit"
                disabled={!isValid || !isDirty || isLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(UpdateEntryModal);
