import api from "../lib/api";

export const AddFeature = (data) => {
  return api.post("/add-new-feature", data);
};

export const UpdateImage = (data, id) => {
  return api.post(`/update-feature-image/${id}`, data);
};

export const UpdateFeatureData = (data, id) => {
  return api.post(`/update-feature-data/${id}`, data);
};

export const DeleteFeature = (data) => {
  const { _id, icon } = data;
  return api.delete(`/delete-feature/${_id}/${icon}`);
};

export const ToggleFeature = (id) => {
  return api.post(`/toggle-feature/${id}`);
};

export const GetAllFeature = () => {
  return api.get("/get-all-feature");
};
