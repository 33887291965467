import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "rsuite";
import { DeleteProduct } from "../../actions/product";

const DeleteProductModal = ({
  deleteProductModal,
  setDeleteProductModal,
  productsRefetch,
  currentProduct,
}) => {
  const { mutate: deleteProduct, isLoading: deleteLoading } = useMutation(
    (data) => DeleteProduct(data),
    {
      onSuccess: (res) => {
        setDeleteProductModal(false);
        productsRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={deleteProductModal}
      onClose={() => setDeleteProductModal(false)}
      size="xs"
    >
      <Modal.Body>Do you want to delete this product ?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => deleteProduct(currentProduct)}
          appearance="primary"
          disabled={deleteLoading}
        >
          Ok
        </Button>
        <Button
          onClick={() => setDeleteProductModal(false)}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProductModal;
