import { createContext, useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "rsuite";
import { GetAdmin } from "../actions/auth";
// import api from "../lib/api";

const AuthContext = createContext({
  user: null,
  isLoggedIn: false,
  signIn: (user) => {},
  signOut: () => {},
  authLoading: true,
});

export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const { isLoading } = useQuery(["adminData"], () => GetAdmin(), {
    onSuccess: ({ data }) => {
      signIn(data);
    },
    onError: () => {
      setAuthLoading(false);
    },
    enabled: !user ? true : false,
  });

  // useQuery(["csrf-token"], () => api.get("/csrf-token"), {
  //   onSuccess: ({ data }) => {
  //     console.log("csrf token", data.csrfToken);
  //     api.defaults.headers.common["X-CSRF-Token"] = data.csrfToken;
  //     // api.interceptors.request.use(function (config) {
  //     //   config.headers["X-CSRF-Token"] = data.csrfToken;
  //     //   return config;
  //     // });
  //   },
  //   retry: true,
  //   retryOnMount: true,
  //   refetchOnMount: true,
  // });

  const signIn = (user) => {
    setUser(user);
    setIsLoggedIn(true);
    setAuthLoading(false);
  };

  const signOut = () => {
    setUser(null);
    setIsLoggedIn(false);
  };

  if (isLoading || authLoading)
    return (
      <div>
        <Loader size="lg" backdrop content="loading..." vertical />
      </div>
    );

  return (
    <AuthContext.Provider
      value={{ user, isLoggedIn, signIn, signOut, authLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
