import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { GetAllCarousel, ToggleCarousel } from "../../actions/carousel";
import AddCarouselModal from "../../components/carousel/AddCarouselModal";
import { FcEditImage, FcRemoveImage } from "react-icons/fc";
import UpdateCarouselModal from "../../components/carousel/UpdateCarouselModal";
import DeleteCarouselModal from "../../components/carousel/DeleteCarouselModal";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";

const Carousel = () => {
  const [addCarouselModal, setAddCarouselModal] = useState(false);
  const [updateCarouselModal, setUpdateCarouselModal] = useState(false);
  const [deleteCarouselModal, setDeleteCarouselModal] = useState(false);

  const [selectedCarousel, setSelectedCarousel] = useState(null);
  const [currentCarousel, setCurrentCarousel] = useState(null);

  const {
    data: carouselData,
    isLoading: carouselLoading,
    isError: carouselError,
    refetch: carouselRefetch,
  } = useQuery(["carousel-data"], GetAllCarousel, {
    select: (res) => res.data,
  });

  const { mutate: toggleCarousel, isLoading: toggleLoading } = useMutation(
    (data) => ToggleCarousel(data),
    {
      onSuccess: (res) => {
        carouselRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "uppercase",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "uppercase",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      maxWidth: "250px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      maxWidth: "400px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "150px",
    },
    {
      name: "Icon",
      selector: (row) => row.icon,
      maxWidth: "fit-content",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  const tableData =
    !carouselLoading && !carouselError
      ? carouselData.map((item, i) => {
          return {
            sno: i + 1,
            title: item.title,
            description: item.description,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  loading={toggleLoading}
                  checked={item.isActive}
                  checkedChildren="Enable"
                  unCheckedChildren="Disabled"
                  onChange={() => toggleCarousel(item._id)}
                />
              </div>
            ),
            icon: (
              <div className="h-[50px] w-[50px] md:h-[80px] md:w-[80px] flex justify-center items-center p-2">
                <img
                  src={process.env.REACT_APP_STATIC_URL + `/${item.icon}`}
                  alt="preview_image"
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-4xl">
                <FcEditImage
                  onClick={() => {
                    setSelectedCarousel(item);
                    setUpdateCarouselModal(true);
                  }}
                  className="cursor-pointer"
                />
                <FcRemoveImage
                  onClick={() => {
                    setCurrentCarousel(item);
                    setDeleteCarouselModal(true);
                  }}
                  className={`cursor-pointer`}
                />
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { icon, actions, status, ...rest } = data;
    return <pre>{JSON.stringify(rest, null, 2)}</pre>;
  };

  return (
    <div className="">
      <AddCarouselModal
        addCarouselModal={addCarouselModal}
        setAddCarouselModal={setAddCarouselModal}
        carouselRefetch={carouselRefetch}
      />

      <UpdateCarouselModal
        updateCarouselModal={updateCarouselModal}
        setUpdateCarouselModal={setUpdateCarouselModal}
        carouselRefetch={carouselRefetch}
        selectedCarousel={selectedCarousel}
      />

      <DeleteCarouselModal
        deleteCarouselModal={deleteCarouselModal}
        setDeleteCarouselModal={setDeleteCarouselModal}
        carouselRefetch={carouselRefetch}
        currentCarousel={currentCarousel}
      />

      <h1 className="text-center text-4xl text-primary font-semibold tracking-wider py-5">
        Carousel
      </h1>
      <button className="add-new-btn" onClick={() => setAddCarouselModal(true)}>
        Add New Carousel
      </button>

      <div className="p-2 md:p-10">
        <DataTable
          title="Carousel List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default Carousel;
