import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { GetAllProduct, ToggleProduct } from "../../actions/product";
import AddProductModal from "../../components/product/AddProductModal";
import { FcEditImage, FcRemoveImage } from "react-icons/fc";
import UpdateProductModal from "../../components/product/UpdateProductModal";
import DeleteProductModal from "../../components/product/DeleteProductModal";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";

const Feature = () => {
  const [addProductModal, setAddProductModal] = useState(false);
  const [updateProductModal, setUpdateProductModal] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);

  const {
    data: productsData,
    isLoading: productsLoading,
    isError: productsError,
    refetch: productsRefetch,
  } = useQuery(["products-data"], GetAllProduct, {
    select: (res) => res.data,
  });

  const { mutate: toggleProduct, isLoading: toggleLoading } = useMutation(
    (data) => ToggleProduct(data),
    {
      onSuccess: (res) => {
        productsRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "uppercase",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "uppercase",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      maxWidth: "250px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      maxWidth: "400px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "150px",
    },
    {
      name: "Icon",
      selector: (row) => row.icon,
      maxWidth: "fit-content",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  const tableData =
    !productsLoading && !productsError
      ? productsData.map((item, i) => {
          return {
            sno: i + 1,
            title: item.title,
            description: item.description,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  loading={toggleLoading}
                  checked={item.isActive}
                  checkedChildren="Enable"
                  unCheckedChildren="Disabled"
                  onChange={() => toggleProduct(item._id)}
                />
              </div>
            ),
            icon: (
              <div className="h-[50px] w-[50px] md:h-[80px] md:w-[80px] flex justify-center items-center p-2">
                <img
                  src={process.env.REACT_APP_STATIC_URL + `/${item.icon}`}
                  alt="preview_image"
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-4xl">
                <FcEditImage
                  onClick={() => {
                    setSelectedProduct(item);
                    setUpdateProductModal(true);
                  }}
                  className="cursor-pointer"
                />
                <FcRemoveImage
                  onClick={() => {
                    setCurrentProduct(item);
                    setDeleteProductModal(true);
                  }}
                  className={`cursor-pointer`}
                />
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { icon, actions, status, ...rest } = data;
    return <pre>{JSON.stringify(rest, null, 2)}</pre>;
  };

  return (
    <div className="">
      <AddProductModal
        addProductModal={addProductModal}
        setAddProductModal={setAddProductModal}
        productsRefetch={productsRefetch}
      />

      <UpdateProductModal
        updateProductModal={updateProductModal}
        setUpdateProductModal={setUpdateProductModal}
        productsRefetch={productsRefetch}
        selectedProduct={selectedProduct}
      />

      <DeleteProductModal
        deleteProductModal={deleteProductModal}
        setDeleteProductModal={setDeleteProductModal}
        productsRefetch={productsRefetch}
        currentProduct={currentProduct}
      />

      <h1 className="text-center text-4xl text-primary font-semibold tracking-wider py-5">
        Product
      </h1>
      <button className="add-new-btn" onClick={() => setAddProductModal(true)}>
        Add New Product
      </button>

      <div className="p-2 md:p-10">
        <DataTable
          title="Product's List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default Feature;
