import api from "../lib/api";

export const AddCompany = (data) => {
  return api.post("/company/add-new-company", data);
};

export const UpdateCompany = (data, id) => {
  return api.post(`/company/update-company-data/${id}`, data);
};

export const UpdateCompanyStatus = (id) => {
  return api.post(`/company/update-company-status/${id}`);
};

export const GetAllCompanies = () => {
  return api.get("/company/get-all-companies");
};

export const GetAllCategories = () => {
  return api.get("/company/get-all-categories");
};
