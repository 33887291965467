import api from "../lib/api";

export const AddCarousel = (data) => {
  return api.post("/add-new-carousel", data);
};

export const UpdateImage = (data, id) => {
  return api.post(`/update-carousel-image/${id}`, data);
};

export const UpdateCarouselData = (data, id) => {
  return api.post(`/update-carousel-data/${id}`, data);
};

export const DeleteCarousel = (data) => {
  const { _id, icon } = data;
  return api.delete(`/delete-carousel/${_id}/${icon}`);
};

export const ToggleCarousel = (id) => {
  return api.post(`/toggle-carousel/${id}`);
};

export const GetAllCarousel = () => {
  return api.get("/get-all-carousel");
};
