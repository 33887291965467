import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import {
  GetAllApplications,
  ToggleApplication,
} from "../actions/job-applications";
import { FcDocument, FcRemoveImage } from "react-icons/fc";
import DeleteApplicationModal from "../components/job-applications/DeleteApplicationModal";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";

const JobApplications = () => {
  const [deleteApplicationModal, setDeleteApplicationModal] = useState(false);

  const [currentApplication, setCurrentApplication] = useState(null);

  const {
    data: applicationsData,
    isLoading: applicationsLoading,
    isError: applicationsError,
    refetch: applicationsRefetch,
  } = useQuery(["job-applications-data"], GetAllApplications, {
    select: (res) => res.data,
  });

  const { mutate: toggleApplication, isLoading: toggleLoading } = useMutation(
    (data) => ToggleApplication(data),
    {
      onSuccess: (res) => {
        applicationsRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "capitalize",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "capitalize",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      maxWidth: "fit-content",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      maxWidth: "200px",
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
      maxWidth: "fit-content",
    },
    {
      name: "Job Type",
      selector: (row) => row.jobType,
      maxWidth: "fit-content",
    },
    {
      name: "Job Profile",
      selector: (row) => row.jobProfile,
      maxWidth: "fit-content",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "fit-content",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  const tableData =
    !applicationsLoading && !applicationsError
      ? applicationsData.map((item, i) => {
          return {
            sno: i + 1,
            name: item.name,
            email: item.email,
            contact: item.phone,
            jobType: item.jobType,
            jobProfile: item.jobProfile,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  loading={toggleLoading}
                  checked={item.isActive}
                  checkedChildren="Enable"
                  unCheckedChildren="Disabled"
                  onChange={() => toggleApplication(item._id)}
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-4xl">
                <FcRemoveImage
                  onClick={() => {
                    setCurrentApplication(item);
                    setDeleteApplicationModal(true);
                  }}
                  className={`cursor-pointer`}
                />
                <a
                  href={process.env.REACT_APP_DOCUMENT_URL + `/${item.resume}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FcDocument className={`cursor-pointer`} />
                </a>
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { actions, status, ...rest } = data;
    return (
      <pre className="break-words whitespace-pre-wrap">
        {JSON.stringify(rest, null, 2)}
      </pre>
    );
  };

  return (
    <div className="">
      <DeleteApplicationModal
        deleteApplicationModal={deleteApplicationModal}
        setDeleteApplicationModal={setDeleteApplicationModal}
        applicationsRefetch={applicationsRefetch}
        currentApplication={currentApplication}
      />

      <h1 className="text-center text-4xl text-primary font-semibold tracking-wider py-5">
        Job Applications
      </h1>

      <div className="p-2 md:p-10">
        <DataTable
          title="Job Applications List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default JobApplications;
