import api from "../lib/api";

export const AddVacancy = (data) => {
  return api.post("/add-new-vacancy", data);
};

export const UpdateImage = (data, id) => {
  return api.post(`/update-vacancy-image/${id}`, data);
};

export const UpdateVacancyData = (data, id) => {
  return api.post(`/update-vacancy-data/${id}`, data);
};

export const DeleteVacancy = (data) => {
  const { _id, icon } = data;
  return api.delete(`/delete-vacancy/${_id}/${icon}`);
};

export const ToggleVacancy = (id) => {
  return api.post(`/toggle-vacancy/${id}`);
};

export const GetAllVacancies = () => {
  return api.get("/get-all-vacancies");
};
