import api from "../lib/api";

export const AddSocialMedia = (data) => {
  return api.post("/add-new-social-media", data);
};

export const UpdateSocialMediaData = (data, id) => {
  return api.post(`/update-social-media-data/${id}`, data);
};

export const DeleteSocialMedia = (data) => {
  return api.delete(`/delete-social-media/${data}`);
};

export const ToggleSocialMedia = (id) => {
  return api.post(`/toggle-social-media/${id}`);
};

export const GetAllSocialMedia = () => {
  return api.get("/get-all-social-media");
};
