import api from "../lib/api";

export const DeleteQuery = (id) => {
  return api.delete(`/delete-query/${id}`);
};

export const ToggleQuery = (id) => {
  return api.post(`/toggle-query/${id}`);
};

export const GetAllQueries = () => {
  return api.get("/get-all-queries");
};
