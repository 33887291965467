import { BsSearch, BsChevronDown, BsPlusCircle } from "react-icons/bs";
import { AiFillEnvironment } from "react-icons/ai";
import { RiDashboardFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { useState } from "react";
import { Drawer } from "rsuite";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { Signout } from "../../actions/auth";
import { toast } from "react-toastify";

const Sidebar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { signOut } = useAuthContext();
  const [currentSubMenu, setCurrentSubMenu] = useState(null);

  const Menus = [
    { title: "Dashboard", path: "/", icon: <RiDashboardFill /> },
    {
      title: "Profile",
      path: "/profile",
      icon: <CgProfile />,
    },
    {
      title: "Job Applications",
      path: "/job-applications",
      icon: <CgProfile />,
    },
    {
      title: "Customer Queries",
      path: "/customer-queries",
      icon: <AiFillEnvironment />,
    },
    {
      title: "Add",
      id: 0,
      icon: <BsPlusCircle />,
      subMenu: true,
      subMenuItems: [
        { title: "Add Services", path: "/services" },
        { title: "Add Vacancies", path: "/vacancies" },
        { title: "Add Carousel", path: "/carousel" },
        { title: "Add Feature", path: "/features" },
        { title: "Add Product", path: "/products" },
        { title: "Add Strategy", path: "/strategies" },
        { title: "Add Social Media", path: "/social-media" },
      ],
    },
    {
      title: "Stocks",
      id: 1,
      icon: <BsPlusCircle />,
      subMenu: true,
      subMenuItems: [
        { title: "Add New Company", path: "/company" },
        { title: "Add New Order", path: "/order" },
        { title: "Ledger", path: "/stocks/ledger" },
      ],
    },
  ];

  const { mutate: mutateLogout } = useMutation(() => Signout(), {
    onSuccess: (res) => {
      signOut();
      localStorage.removeItem("authToken");
      navigate("/login");
      toast.success(res.data.message);
    },
    onError: (err) => {
      toast.error(err.response.data.error.message);
    },
  });

  return (
    <Drawer
      placement="left"
      size="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Drawer.Body className="bg-darkPurple p-0">
        <div className="w-full h-full pt-16 px-10">
          <div className="flex items-center space-x-3">
            <div className="rounded-full bg-white flex justify-between items-center p-3">
              <img
                className={`h-12 w-12 cursor-pointer duration-500 ${
                  open && "rotate-[360deg]"
                }`}
                src="/logo.png"
                alt="Logo"
              />
            </div>

            <div className="flex flex-col text-xl space-y-1 font-medium text-white hover:text-secondary">
              <span className="">Kalazara</span>
              <span className="">Technologies</span>
            </div>

            {/* <h1
              className={`text-white origin-left font-medium text-2xl duration-300 ${
                !open && "scale-0"
              }`}
            >
              Tailwind
            </h1> */}
          </div>

          <div
            className={`flex items-center rounded-md bg-lightWhite mt-6 ${
              !open ? "px-2.5" : "px-4"
            } py-2`}
          >
            <BsSearch
              className={`text-white text-lg block float-left cursor-pointer ${
                open && "mr-2"
              }`}
            />
            <input
              type="search"
              placeholder="Search"
              className={`text-base bg-transparent w-full text-white focus:outline-none ${
                !open && "hidden"
              }`}
            />
          </div>

          <ul className="pt-2">
            {Menus.map((menu, index) => (
              <div key={index}>
                {menu.path ? (
                  <li>
                    <NavLink
                      to={menu.path}
                      onClick={() => {
                        setOpen(false);
                      }}
                      className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-lightWhite rounded-md no-underline hover:no-underline hover:text-gray-300 ${
                        menu.spacing ? "mt-9" : "mt-2"
                      }`}
                    >
                      <span className="text-2xl block float-left">
                        {menu.icon ? menu.icon : <RiDashboardFill />}
                      </span>
                      <span
                        className={`text-base font-medium flex-1 duration-200 ${
                          !open && "hidden"
                        }`}
                      >
                        {menu.title}
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  <li
                    className={`flex text-gray-300 text-sm items-center gap-x-4 cursor-pointer p-2 hover:bg-lightWhite rounded-md ${
                      menu.spacing ? "mt-9" : "mt-2"
                    }`}
                    onClick={() => {
                      if (menu.id === currentSubMenu) {
                        setCurrentSubMenu(null);
                      } else {
                        setCurrentSubMenu(menu.id);
                      }
                    }}
                  >
                    <span className="text-2xl block float-left">
                      {menu.icon ? menu.icon : <RiDashboardFill />}
                    </span>
                    <span
                      className={`text-base font-medium flex-1 duration-200 ${
                        !open && "hidden"
                      }`}
                    >
                      {menu.title}
                    </span>
                    {menu.subMenu && open && (
                      <BsChevronDown
                        className={`duration-300 ${
                          // subMenuOpen &&
                          menu.id === currentSubMenu && "rotate-180"
                        }`}
                      />
                    )}
                  </li>
                )}

                {menu.subMenu &&
                  menu.id === currentSubMenu &&
                  // subMenuOpen &&
                  open && (
                    <ul>
                      {menu.subMenuItems.map((items, i) => (
                        <li key={i}>
                          <NavLink
                            to={items.path}
                            onClick={() => {
                              setOpen(false);
                            }}
                            className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 px-5 hover:bg-lightWhite rounded-md no-underline hover:no-underline hover:text-gray-300`}
                          >
                            {items.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
            ))}
            <li
              className="text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-lightWhite rounded-md no-underline hover:no-underline hover:text-gray-300 mt-9"
              onClick={mutateLogout}
              // disabled={logoutLoading}
            >
              <span className="text-2xl block float-left">
                <RiDashboardFill />
              </span>
              <span className={`text-base font-medium flex-1 duration-200`}>
                Logout
              </span>
            </li>
          </ul>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};

export default Sidebar;
