import React from "react";
// import { toast } from "react-toastify";

const Home = () => {
  console.log("Home chala");

  // toast.success("Welcome Admin");

  // const { isLoading } = useQuery(["adminData"], () => GetAdmin(), {
  //   onSuccess: (res) => console.log("res hai", res),
  // });

  // if (isLoading) return <div>Loading...</div>;
  return <div>Index</div>;
};

export default Home;
