import api from "../lib/api";

export const AddEntry = (data) => {
  return api.post("/ledger/add-new-entry", data);
};

export const UpdateEntry = (data, id) => {
  return api.post(`/ledger/update-entry-data/${id}`, data);
};

export const UpdateEntryStatus = (id) => {
  return api.post(`/ledger/update-entry-status/${id}`);
};

export const GetAllEntries = () => {
  return api.get("/ledger/get-user-entries");
};
