import api from "../lib/api";

export const AddProfile = (data) => {
  return api.post("/add-new-profile", data);
};

export const UpdateProfileData = (data, id) => {
  return api.post(`/update-profile-data/${id}`, data);
};

export const GetAllProfiles = () => {
  return api.get("/get-all-profiles");
};
