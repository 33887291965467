import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "rsuite";
import { DeleteQuery } from "../../actions/customer-queries";

const DeleteQueryModal = ({
  deleteQueryModal,
  setDeleteQueryModal,
  queriesRefetch,
  currentQuery,
}) => {
  const { mutate: deleteQuery, isLoading: deleteLoading } = useMutation(
    (data) => DeleteQuery(data),
    {
      onSuccess: (res) => {
        setDeleteQueryModal(false);
        queriesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={deleteQueryModal}
      onClose={() => setDeleteQueryModal(false)}
      size="xs"
    >
      <Modal.Body>Do you want to delete this query ?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => deleteQuery(currentQuery?._id)}
          appearance="primary"
          disabled={deleteLoading}
        >
          Ok
        </Button>
        <Button onClick={() => setDeleteQueryModal(false)} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteQueryModal;
