import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";
import moment from "moment";
import {
  MdKeyboardBackspace,
  MdOutlineEdit,
  MdOutlineShoppingCart,
  MdTrendingUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { GetAllEntries, UpdateEntryStatus } from "../../actions/ledger";
import AddEntryModal from "../../components/ledger/AddEntryModal";
import UpdateEntryModal from "../../components/ledger/UpdateEntryModal";
import InfoCard from "../../components/cards/InfoCard";

const Ledger = () => {
  const navigate = useNavigate();
  const [addEntryModal, setAddEntryModal] = useState(false);
  const [updateEntryModal, setUpdateEntryModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const [totalHoldings, setTotalHoldings] = useState({
    amount: 0,
    percentage: 0,
  });
  const [currentHoldings, setCurrentHoldings] = useState({
    amount: 0,
    percentage: 0,
  });

  const {
    data: ledgerData,
    isLoading: ledgerLoading,
    isError: ledgerError,
    refetch: ledgerRefetch,
  } = useQuery(["ledger-data"], GetAllEntries, {
    select: (res) => res.data,
  });

  const { mutate: toggleEntryStatus, isLoading: toggleLoading } = useMutation(
    (data) => UpdateEntryStatus(data),
    {
      onSuccess: (res) => {
        ledgerRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "capitalize",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "capitalize",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "180px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
      width: "120px",
      style: {
        textTransform: "capitalize",
        // backgroundColor: "#2c2def",
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      maxWidth: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "150px",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  const tableData =
    !ledgerLoading && !ledgerError
      ? ledgerData.map((item, i) => {
          return {
            sno: i + 1,
            date: moment(item.bidDate).format("DD-MM-YYYY"),
            type: (
              <span
                className={`text-sm ${
                  item.bidType === "credit" ? "bg-green-500" : "bg-red-500"
                } text-white rounded-md px-2 py-1`}
              >
                {item.bidType}
              </span>
            ),
            amount: item.amount,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  size={"md"}
                  loading={toggleLoading}
                  checked={item.isActive}
                  onChange={() => toggleEntryStatus(item._id)}
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-2xl">
                <MdOutlineEdit
                  onClick={() => {
                    setSelectedEntry(item);
                    setUpdateEntryModal(true);
                  }}
                  className="cursor-pointer"
                />
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { actions, status, ...rest } = data;
    return <pre>{JSON.stringify(rest, null, 2)}</pre>;
  };

  useEffect(() => {
    if (ledgerData) {
      getProfit();
    }
  }, [ledgerData]);

  const getProfit = () => {
    const { totalAmount, totalCreditAmount, totalDebitAmount } =
      ledgerData.reduce(
        (acc, order) => {
          const { bidType, amount } = order;
          bidType === "credit"
            ? (acc.totalCreditAmount += amount)
            : (acc.totalDebitAmount += amount);
          acc.totalAmount += amount;
          return acc;
        },
        {
          totalAmount: 0,
          totalCreditAmount: 0,
          totalDebitAmount: 0,
        }
      );

    // const amount = totalSellAmount - totalBuyAmount;
    // const percentage = (
    //   (totalSellAmount * 100) / totalBuyAmount - 100 || 0
    // ).toFixed(2);

    // const totalQuantity = totalBuyQuantity - totalSellQuantity;
    // const totalQuantityPercentage =
    //   totalQuantity === 0
    //     ? 0
    //     : ((totalQuantity * 100) / totalBuyQuantity || 0).toFixed(2);

    const currentHoldingPercentage =
      totalCreditAmount === 0
        ? 0
        : (
            ((totalCreditAmount - totalDebitAmount) * 100) /
              totalCreditAmount || 0
          ).toFixed(2);

    setTotalHoldings({ amount: totalCreditAmount, percentage: 0 });
    setCurrentHoldings({
      amount: totalCreditAmount - totalDebitAmount,
      percentage: currentHoldingPercentage,
    });
  };

  return (
    <div className="">
      <AddEntryModal
        isOpen={addEntryModal}
        setIsOpen={setAddEntryModal}
        refetchList={ledgerRefetch}
      />

      <UpdateEntryModal
        isOpen={updateEntryModal}
        setIsOpen={setUpdateEntryModal}
        refetchList={ledgerRefetch}
        itemData={selectedEntry}
      />

      <div className="flex items-center w-11/12 mx-auto">
        <MdKeyboardBackspace
          className="text-primary text-4xl md:text-6xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="text-center text-xl md:text-4xl text-primary font-semibold tracking-wider py-5 capitalize flex-1">
          Ledger
        </h1>
      </div>
      <button className="add-new-btn" onClick={() => setAddEntryModal(true)}>
        Add New Entry
      </button>

      <div className="flex flex-wrap items-center justify-start gap-10 p-2 md:p-10">
        <InfoCard
          title={"total investment"}
          separator={"Rs."}
          data1={totalHoldings.amount}
          data2={totalHoldings.percentage}
          icon={MdTrendingUp}
        />
        <InfoCard
          title={"current holdings"}
          separator={"Qty."}
          data1={currentHoldings.amount}
          data2={currentHoldings.percentage}
          icon={MdOutlineShoppingCart}
        />
      </div>

      <div className="p-2 md:p-10 w-full">
        <DataTable
          title="Ledger Entries"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default Ledger;
