import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { GetAllServices, ToggleService } from "../../actions/services";
import AddServiceModal from "../../components/services/AddServiceModal";
import { FcEditImage, FcRemoveImage } from "react-icons/fc";
import UpdateServiceModal from "../../components/services/UpdateServiceModal";
import DeleteServiceModal from "../../components/services/DeleteServiceModal";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";

const Services = () => {
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [updateServiceModal, setUpdateServiceModal] = useState(false);
  const [deleteServiceModal, setDeleteServiceModal] = useState(false);

  const [selectedService, setSelectedService] = useState(null);
  const [currentService, setCurrentService] = useState(null);

  const {
    data: servicesData,
    isLoading: servicesLoading,
    isError: servicesError,
    refetch: servicesRefetch,
  } = useQuery(["services-data"], GetAllServices, {
    select: (res) => res.data,
  });

  const { mutate: toggleService, isLoading: toggleLoading } = useMutation(
    (data) => ToggleService(data),
    {
      onSuccess: (res) => {
        servicesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "uppercase",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "uppercase",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      maxWidth: "250px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      maxWidth: "400px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "150px",
    },
    {
      name: "Icon",
      selector: (row) => row.icon,
      maxWidth: "fit-content",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  // loading={loading} onChange={toggle}

  const tableData =
    !servicesLoading && !servicesError
      ? servicesData.map((item, i) => {
          return {
            sno: i + 1,
            title: item.title,
            description: item.description,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  loading={toggleLoading}
                  checked={item.isActive}
                  checkedChildren="Enable"
                  unCheckedChildren="Disabled"
                  onChange={() => toggleService(item._id)}
                />
              </div>
            ),
            icon: (
              <div className="h-[50px] w-[50px] md:h-[80px] md:w-[80px] flex justify-center items-center p-2">
                <img
                  src={process.env.REACT_APP_STATIC_URL + `/${item.icon}`}
                  alt="preview_image"
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-4xl">
                <FcEditImage
                  onClick={() => {
                    setSelectedService(item);
                    setUpdateServiceModal(true);
                  }}
                  className="cursor-pointer"
                />
                <FcRemoveImage
                  onClick={() => {
                    setCurrentService(item);
                    setDeleteServiceModal(true);
                  }}
                  className={`cursor-pointer`}
                />
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { icon, actions, status, ...rest } = data;
    return <pre>{JSON.stringify(rest, null, 2)}</pre>;
  };

  return (
    <div className="">
      <AddServiceModal
        addServiceModal={addServiceModal}
        setAddServiceModal={setAddServiceModal}
        servicesRefetch={servicesRefetch}
      />

      <UpdateServiceModal
        updateServiceModal={updateServiceModal}
        setUpdateServiceModal={setUpdateServiceModal}
        servicesRefetch={servicesRefetch}
        selectedService={selectedService}
      />

      <DeleteServiceModal
        deleteServiceModal={deleteServiceModal}
        setDeleteServiceModal={setDeleteServiceModal}
        servicesRefetch={servicesRefetch}
        currentService={currentService}
      />

      <h1 className="text-center text-4xl text-primary font-semibold tracking-wider py-5">
        Services
      </h1>
      <button className="add-new-btn" onClick={() => setAddServiceModal(true)}>
        Add New Service
      </button>

      <div className="p-2 md:p-10">
        <DataTable
          title="Service's List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default Services;
