import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import * as Yup from "yup";
import { UpdateImage, UpdateVacancyData } from "../../actions/vacancies";

const UpdateVacancyModal = ({
  updateVacancyModal,
  setUpdateVacancyModal,
  vacanciesRefetch,
  selectedVacancy,
}) => {
  const [preview, setPreview] = useState();
  const [updateIconImage, setUpdateIconImage] = useState();

  const initialValues = {
    title: "",
    description: "",
    experience: "",
    location: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    experience: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (selectedVacancy) {
      setValue("title", selectedVacancy.title);
      setValue("description", selectedVacancy.description);
      setValue("experience", selectedVacancy.experience);
      setValue("location", selectedVacancy.location);
      setPreview(process.env.REACT_APP_STATIC_URL + `/${selectedVacancy.icon}`);
    }
  }, [selectedVacancy, setValue]);

  const { mutate: mutateImage } = useMutation(
    (data) => UpdateImage(data, selectedVacancy._id),
    {
      onSuccess: (res) => {
        setUpdateVacancyModal(false);
        vacanciesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const setImageChange = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setUpdateIconImage(e.target.files[0]);
  };

  const handleImageData = (e) => {
    e.preventDefault();
    let imageData = new FormData();
    imageData.append("icon", updateIconImage);
    imageData.append("oldImage", selectedVacancy.icon);
    mutateImage(imageData);
  };

  const { mutate: mutateDataUpdate, isLoading: updateLoading } = useMutation(
    (data) => UpdateVacancyData(data, selectedVacancy._id),
    {
      onSuccess: (res) => {
        setUpdateVacancyModal(false);
        vacanciesRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const handleData = (values) => {
    mutateDataUpdate(values);
  };

  return (
    <Modal
      backdrop="static"
      open={updateVacancyModal}
      onClose={() => setUpdateVacancyModal(false)}
      className=""
    >
      <Modal.Header>
        <Modal.Title className="text-primary text-lg font-semibold text-center">
          Update Vacancy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="grid grid-cols-1 gap-y-2 md:gap-y-0 md:grid-cols-2 items-center mb-6">
            <div className="mx-auto flex justify-center items-center p-2 h-[80px] w-[80px] md:h-[150px] md:w-[150px]">
              <img src={preview} alt="preview_image" />
            </div>

            <form onSubmit={handleImageData} encType="multipart/form-data">
              <div className="w-full mb-2">
                <label className="custom-label" htmlFor="icon">
                  Choose file to update
                </label>
                <input
                  className="custom-input"
                  aria-describedby="file_input_help"
                  id="icon"
                  name="icon"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setImageChange(e);
                  }}
                />
              </div>
              <button className="submit-btn" disabled={!updateIconImage}>
                Click to Submit
              </button>
            </form>
          </div>

          <hr className="mb-6" />

          <form onSubmit={handleSubmit(handleData)}>
            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="title">
                Title
              </label>
              <input
                className="custom-input"
                id="title"
                name="title"
                type="text"
                {...register("title")}
              />
              {errors.title && (
                <p className="text-red-600">{errors.title.message}</p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-start mb-6">
              <div className="w-full">
                <label className="custom-label" htmlFor="experience">
                  Experience
                </label>
                <input
                  className="custom-input"
                  id="experience"
                  name="experience"
                  type="text"
                  {...register("experience")}
                />
                {errors.experience && (
                  <p className="text-red-600">{errors.experience.message}</p>
                )}
              </div>
              <div className="w-full">
                <label className="custom-label" htmlFor="location">
                  Location
                </label>
                <input
                  className="custom-input"
                  id="location"
                  name="location"
                  type="text"
                  {...register("location")}
                />
                {errors.location && (
                  <p className="text-red-600">{errors.location.message}</p>
                )}
              </div>
            </div>

            <div className="w-full mb-6">
              <label className="custom-label" htmlFor="description">
                Description
              </label>
              <textarea
                className="custom-input"
                id="description"
                name="description"
                rows={4}
                {...register("description")}
              />
              {errors.description && (
                <p className="text-red-600">{errors.description.message}</p>
              )}
            </div>
            <div className="flex items-center justify-center gap-5 max-w-sm mx-auto">
              <button
                className="submit-btn"
                type="submit"
                disabled={!isValid || !isDirty || updateLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateVacancyModal;
