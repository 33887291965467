import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/auth/LoginForm";
import { useAuthContext } from "../../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useAuthContext();

  console.log("login chala");

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [isLoggedIn, navigate, user]);

  return (
    <div className="h-screen w-screen grid grid-cols-1 lg:grid-cols-2 items-center">
      <div className="flex justify-center">
        <img
          src="/logo.png"
          className="w-[300px] h-[300px] md:w-[500px] md:h-[500px]"
          alt="Logo"
        />
      </div>
      <div className="max-w-max p-5 mx-auto">
        <div className="text-2xl md:text-5xl font-semibold mb-5">
          <span className="text-secondary hover:text-primary duration-300">
            Kalazara
          </span>
          &nbsp;
          <span className="text-primary hover:text-secondary duration-300">
            Technologies
          </span>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;

// {
/* <section className="h-screen">
<div className="container px-6 py-12 h-full">
  <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
    <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0 flex justify-center items-center">
      <img
        src="/logo.png"
        className="w-[300px] h-[300px] md:w-[500px] md:h-[500px]"
        alt="Logo"
      />
    </div>
    <div className="md:w-8/12 lg:w-5/12 lg:ml-20">
      <div className="text-2xl md:text-4xl lg:text-6xl font-semibold mb-5">
        <span className="">Kalazara</span>&nbsp;<span>Technologies</span>
      </div>
      <LoginForm />
    </div>
  </div>
</div>
</section> */
// }
