import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import {
  GetAllSocialMedia,
  ToggleSocialMedia,
} from "../../actions/social-media";
import AddSocialMediaModal from "../../components/social-media/AddSocialMediaModal";
import { FcEditImage, FcRemoveImage } from "react-icons/fc";
import UpdateSocialMediaModal from "../../components/social-media/UpdateSocialMediaModal";
import DeleteSocialMediaModal from "../../components/social-media/DeleteSocialMediaModal";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";

const SocialMedia = () => {
  const [addSocialMediaModal, setAddSocialMediaModal] = useState(false);
  const [updateSocialMediaModal, setUpdateSocialMediaModal] = useState(false);
  const [deleteSocialMediaModal, setDeleteSocialMediaModal] = useState(false);

  const [selectedSocialMedia, setSelectedSocialMedia] = useState(null);
  const [currentSocialMedia, setCurrentSocialMedia] = useState(null);

  const {
    data: socialMediaData,
    isLoading: socialMediaLoading,
    isError: socialMediaError,
    refetch: socialMediaRefetch,
  } = useQuery(["social-media-data"], GetAllSocialMedia, {
    select: (res) => res.data,
  });

  const { mutate: toggleSocialMedia, isLoading: toggleLoading } = useMutation(
    (data) => ToggleSocialMedia(data),
    {
      onSuccess: (res) => {
        socialMediaRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "capitalize",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "capitalize",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      maxWidth: "110px",
    },
    {
      name: "URL",
      selector: (row) => row.address,
      maxWidth: "200px",
    },
    {
      name: "Icon",
      selector: (row) => row.icon,
      maxWidth: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "150px",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  const tableData =
    !socialMediaLoading && !socialMediaError
      ? socialMediaData.map((item, i) => {
          return {
            sno: i + 1,
            title: item.title,
            address: item.address,
            icon: item.icon,
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  loading={toggleLoading}
                  checked={item.isActive}
                  checkedChildren="Enable"
                  unCheckedChildren="Disabled"
                  onChange={() => toggleSocialMedia(item._id)}
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-4xl">
                <FcEditImage
                  onClick={() => {
                    setSelectedSocialMedia(item);
                    setUpdateSocialMediaModal(true);
                  }}
                  className="cursor-pointer"
                />
                <FcRemoveImage
                  onClick={() => {
                    setCurrentSocialMedia(item);
                    setDeleteSocialMediaModal(true);
                  }}
                  className={`cursor-pointer`}
                />
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { actions, status, ...rest } = data;
    return <pre>{JSON.stringify(rest, null, 2)}</pre>;
  };

  return (
    <div className="">
      <AddSocialMediaModal
        addSocialMediaModal={addSocialMediaModal}
        setAddSocialMediaModal={setAddSocialMediaModal}
        socialMediaRefetch={socialMediaRefetch}
      />

      <UpdateSocialMediaModal
        updateSocialMediaModal={updateSocialMediaModal}
        setUpdateSocialMediaModal={setUpdateSocialMediaModal}
        socialMediaRefetch={socialMediaRefetch}
        selectedSocialMedia={selectedSocialMedia}
      />

      <DeleteSocialMediaModal
        deleteSocialMediaModal={deleteSocialMediaModal}
        setDeleteSocialMediaModal={setDeleteSocialMediaModal}
        socialMediaRefetch={socialMediaRefetch}
        currentSocialMedia={currentSocialMedia}
      />

      <h1 className="text-center text-4xl text-primary font-semibold tracking-wider py-5">
        Social Media
      </h1>
      <button
        className="add-new-btn"
        onClick={() => setAddSocialMediaModal(true)}
      >
        Add New Social Media
      </button>

      <div className="p-2 md:p-10">
        <DataTable
          title="Social Media's List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default SocialMedia;
