import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetAllOrdersByCompany } from "../../actions/order";
import moment from "moment";
import {
  MdKeyboardBackspace,
  MdOutlineArrowUpward,
  MdOutlineShoppingCart,
  MdTrendingUp,
} from "react-icons/md";
import InfoCard from "../../components/cards/InfoCard";
import { GetAllCompanies } from "../../actions/company";
import { Loader } from "rsuite";

const CompanyAnalysis = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const companyTitle = useLocation().state?.companyTitle || "";

  const [companyData, setCompanyData] = useState({
    companyId: companyId,
    companyTitle: companyTitle,
  });

  const [totalInvestment, setTotalInvestment] = useState({
    amount: 0,
    percentage: 0,
  });

  const [currentInvestment, setCurrentInvestment] = useState({
    amount: 0,
    percentage: 0,
  });

  const [totalProfit, setTotalProfit] = useState({
    amount: 0,
    percentage: 0,
  });
  const [currentHoldings, setCurrentHoldings] = useState({
    quantity: 0,
    percentage: 0,
  });

  const [grossProfit, setGrossProfit] = useState({
    amount: 0,
    percentage: 0,
  });

  const {
    data: companiesData,
    isLoading: companiesLoading,
    isError: companiesError,
    refetch: companiesRefetch,
  } = useQuery(["companies-data"], GetAllCompanies, {
    select: (res) => res.data,
  });

  const {
    data: companyOrdersData,
    isLoading: companyOrdersLoading,
    isError: companyOrdersError,
    refetch: companyOrdersRefetch,
  } = useQuery(
    ["company-orders-data", companyData],
    () => GetAllOrdersByCompany(companyData.companyId),
    {
      select: (res) => res.data,
      enabled: !!companyData,
    }
  );

  useEffect(() => {
    if (companyOrdersData) {
      getProfit();
    }
  }, [companyOrdersData]);

  const getProfit = () => {
    const {
      totalBuyAmount,
      totalSellAmount,
      totalBuyQuantity,
      totalSellQuantity,
    } = companyOrdersData.reduce(
      (acc, order) => {
        const { bidType, amount, quantity } = order;
        bidType === "buy"
          ? (acc.totalBuyAmount += amount)
          : (acc.totalSellAmount += amount);
        bidType === "buy"
          ? (acc.totalBuyQuantity += quantity)
          : (acc.totalSellQuantity += quantity);
        return acc;
      },
      {
        totalBuyAmount: 0,
        totalSellAmount: 0,
        totalBuyQuantity: 0,
        totalSellQuantity: 0,
      }
    );

    const amount = (totalSellAmount - totalBuyAmount).toFixed(2);
    const percentage = (
      (totalSellAmount * 100) / totalBuyAmount - 100 || 0
    ).toFixed(2);

    const totalQuantity = totalBuyQuantity - totalSellQuantity;
    const totalQuantityPercentage =
      totalQuantity === 0
        ? 0
        : ((totalQuantity * 100) / totalBuyQuantity || 0).toFixed(2);

    const currentGP =
      totalSellQuantity === 0
        ? 0
        : (
            (totalSellAmount / totalSellQuantity -
              totalBuyAmount / totalBuyQuantity) *
            totalSellQuantity
          ).toFixed(2);
    const currentGPPercentage = (
      (totalSellAmount * 100) /
        ((totalBuyAmount / totalBuyQuantity) * totalSellQuantity) -
        100 || 0
    ).toFixed(2);

    const currentAmount =
      totalQuantity === 0 ? 0 : (totalBuyAmount - totalSellAmount).toFixed(2);
    const currentAmountPer =
      totalQuantity === 0
        ? 0
        : ((currentAmount * 100) / totalBuyAmount).toFixed(2);

    setTotalInvestment({ amount: totalBuyAmount.toFixed(2), percentage: 0 });
    setCurrentInvestment({
      amount: currentAmount,
      percentage: currentAmountPer,
    });
    setTotalProfit({ amount, percentage });
    setCurrentHoldings({
      quantity: totalQuantity,
      percentage: totalQuantityPercentage,
    });
    setGrossProfit({ amount: currentGP, percentage: currentGPPercentage });
  };

  const handleCompanyChange = (e) => {
    const value = e.target.value;
    const currentData = companiesData?.find((item) => item._id === value);
    setCompanyData({
      companyId: value,
      companyTitle: currentData.title,
    });
  };

  if (companyOrdersLoading)
    return (
      <div>
        <Loader size="lg" backdrop content="loading..." vertical />
      </div>
    );

  return (
    <div>
      <div className="flex flex-wrap items-center w-11/12 mx-auto">
        <MdKeyboardBackspace
          className="text-primary text-4xl md:text-6xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="text-center text-xl md:text-4xl text-primary font-semibold tracking-wider py-5 capitalize flex-1">
          {companyData?.companyTitle}
        </h1>

        <div className="">
          <label className="custom-label" htmlFor="company">
            Company
          </label>
          <select
            className="custom-input capitalize"
            id="company"
            name="company"
            value={companyData.companyId}
            onChange={(e) => {
              handleCompanyChange(e);
            }}
          >
            <option value="">Select One</option>
            {companiesData &&
              companiesData.map((item, i) => (
                <option key={i} value={item._id} className="capitalize">
                  {item.title}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-start gap-10 p-2 md:p-10 mt-5 md:mt-0">
        <InfoCard
          title={"total investment"}
          separator={"Rs."}
          data1={totalInvestment.amount}
          data2={totalInvestment.percentage}
          icon={MdTrendingUp}
        />
        <InfoCard
          title={"current investment"}
          separator={"Rs."}
          data1={currentInvestment.amount}
          data2={currentInvestment.percentage}
          icon={MdTrendingUp}
        />
        <InfoCard
          title={"profit"}
          separator={"Rs."}
          data1={totalProfit.amount}
          data2={totalProfit.percentage}
          icon={MdTrendingUp}
        />
        <InfoCard
          title={"current holdings"}
          separator={"Qty."}
          data1={currentHoldings.quantity}
          data2={currentHoldings.percentage}
          icon={MdOutlineShoppingCart}
        />
        <InfoCard
          title={"gross profit"}
          separator={"Rs."}
          data1={grossProfit.amount}
          data2={grossProfit.percentage}
          icon={MdTrendingUp}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-3 px-2 py-5 md:p-10">
        {/* BUY TABLE START */}
        <div className="flex flex-col h-full col-span-1">
          <div className="w-full mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">Buying Orders</h2>
            </header>
            <div className="p-3">
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                    <tr>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Date</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Amount</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-center">Bid</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-center">SBid</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-center">
                          Quantity
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {companyOrdersData &&
                      companyOrdersData
                        .filter((item) => item.bidType === "buy")
                        .map((item, i) => (
                          <tr key={i}>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left">
                                {moment(item.orderDate).format("DD-MM-YYYY")}
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left font-medium text-green-500">
                                &nbsp;{item.amount.toFixed(2)}&nbsp;Rs.
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-center font-medium text-green-500">
                                {(item.amount / item.quantity).toFixed(2)}
                                &nbsp;Rs.
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-center font-medium text-red-500">
                                {(
                                  ((item.amount / item.quantity) * 108) /
                                  100
                                ).toFixed(2)}
                                &nbsp;Rs.
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-center font-medium">
                                {item.quantity}
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* BUY TABLE END */}
        {/* SELL TABLE START */}
        <div className="flex flex-col h-full col-span-1">
          <div className="w-full mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">Selling Orders</h2>
            </header>
            <div className="p-3">
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                    <tr>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Date</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Amount</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-center">Bid</div>
                      </th>

                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-center">
                          Quantity
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {companyOrdersData &&
                      companyOrdersData
                        .filter((item) => item.bidType === "sell")
                        .map((item, i) => (
                          <tr key={i}>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left">
                                {moment(item.orderDate).format("DD-MM-YYYY")}
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left font-medium text-red-500">
                                &nbsp;{item.amount.toFixed(2)}&nbsp;Rs.
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-center font-medium text-red-500">
                                {(item.amount / item.quantity).toFixed(2)}
                                &nbsp;Rs.
                              </div>
                            </td>

                            <td className="p-2 whitespace-nowrap">
                              <div className="text-center font-medium">
                                {item.quantity}
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* SELL TABLE END */}
      </div>
    </div>
  );
};

export default CompanyAnalysis;
