import NavBar from "./Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useEffect } from "react";

const exceptions = ["/login", "/signup"];

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const location = useLocation();

  useEffect(() => {
    if (!user && !exceptions.includes(location.pathname)) {
      navigate("/login");
    }
  }, [location.pathname, navigate, user]);

  // console.log("location", location);
  if (exceptions.includes(location.pathname)) return <>{children}</>;

  return (
    <div className="w-full min-h-screen">
      <NavBar />
      <div className="">{children}</div>
    </div>
  );
};

export default Layout;
