import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FcEditImage } from "react-icons/fc";
import { Toggle } from "rsuite";
import { toast } from "react-toastify";
import { GetAllCompanies } from "../../actions/company";
import { GetAllOrders, UpdateOrderStatus } from "../../actions/order";
import AddOrderModal from "../../components/order/AddOrderModal";
import moment from "moment";
import {
  MdKeyboardBackspace,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import UpdateOrderModal from "../../components/order/UpdateOrderModal";
import { Link, useNavigate } from "react-router-dom";

const Order = () => {
  const navigate = useNavigate();
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [updateOrderModal, setUpdateOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const {
    data: ordersData,
    isLoading: ordersLoading,
    isError: ordersError,
    refetch: ordersRefetch,
  } = useQuery(["orders-data"], GetAllOrders, {
    select: (res) => res.data,
  });

  const { data: companiesData } = useQuery(
    ["companies-data"],
    GetAllCompanies,
    {
      select: (res) => res.data,
    }
  );

  const { mutate: toggleOrderStatus, isLoading: toggleLoading } = useMutation(
    (data) => UpdateOrderStatus(data),
    {
      onSuccess: (res) => {
        ordersRefetch();
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response.data.error);
      },
    }
  );

  const customStyles = {
    table: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    header: {
      style: {
        maxWidth: "fit-content",
        margin: "auto",
      },
    },
    rows: {
      style: {
        textTransform: "capitalize",
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        textTransform: "capitalize",
        fontSize: "17px",
        color: "#ffffff",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#233180",
      },
    },
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row) => row.sno,
      maxWidth: "10px",
    },
    {
      name: "Company",
      selector: (row) => row.company,
      maxWidth: "300px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
      width: "120px",
      style: {
        textTransform: "capitalize",
        // backgroundColor: "#2c2def",
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      maxWidth: "120px",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      width: "120px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "180px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      maxWidth: "150px",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      maxWidth: "fit-content",
    },
  ];

  const tableData =
    !ordersLoading && !ordersError
      ? ordersData.map((item, i) => {
          return {
            sno: i + 1,
            company: item.company.title,
            type: (
              <span
                className={`text-sm ${
                  item.bidType === "buy" ? "bg-green-500" : "bg-red-500"
                } text-white rounded-md px-2 py-1`}
              >
                {item.bidType}
              </span>
            ),
            amount: item.amount,
            quantity: item.quantity,
            date: moment(item.orderDate).format("DD-MM-YYYY"),
            status: (
              <div className="flex justify-center items-center p-1">
                <Toggle
                  size={"md"}
                  loading={toggleLoading}
                  checked={item.isActive}
                  // checkedChildren="Enable"
                  // unCheckedChildren="Disabled"
                  onChange={() => toggleOrderStatus(item._id)}
                />
              </div>
            ),
            actions: (
              <div className="flex justify-center items-center space-x-5 text-2xl">
                <MdOutlineEdit
                  onClick={() => {
                    setSelectedOrder(item);
                    setUpdateOrderModal(true);
                  }}
                  className="cursor-pointer"
                />
                <Link
                  to={`/company/company-analysis/${item.company._id}`}
                  state={{ companyTitle: item.company.title }}
                >
                  <MdOutlineRemoveRedEye className="cursor-pointer" />
                </Link>
              </div>
            ),
          };
        })
      : [];

  const ExpandedComponent = ({ data }) => {
    const { actions, status, ...rest } = data;
    return <pre>{JSON.stringify(rest, null, 2)}</pre>;
  };

  return (
    <div className="">
      <AddOrderModal
        isOpen={addOrderModal}
        setIsOpen={setAddOrderModal}
        refetchList={ordersRefetch}
        primaryData={companiesData}
      />

      <UpdateOrderModal
        isOpen={updateOrderModal}
        setIsOpen={setUpdateOrderModal}
        refetchList={ordersRefetch}
        itemData={selectedOrder}
        primaryData={companiesData}
      />

      <div className="flex items-center w-11/12 mx-auto">
        <MdKeyboardBackspace
          className="text-primary text-4xl md:text-6xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="text-center text-xl md:text-4xl text-primary font-semibold tracking-wider py-5 capitalize flex-1">
          Orders
        </h1>
      </div>
      <button className="add-new-btn" onClick={() => setAddOrderModal(true)}>
        Add New Order
      </button>

      <div className="p-2 md:p-10 w-full">
        <DataTable
          title="Orders List"
          columns={columns}
          data={tableData}
          customStyles={customStyles}
          striped
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default Order;
